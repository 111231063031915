<template>
  <div class="partners">
    <div class="header">
      <div class="header-img">
        <div style="display: flex;" @click="jumpToHome">
          <div>
            <img style="width:0.75rem;height:0.75rem;object-fit:cover;" src="../../static/images/home-b-t.png" alt="">
          </div>
          <div style="margin-top: 0.1rem;">
            <img style="width:1.47rem;height:0.49rem;object-fit:cover;vertical-align: super;margin-left: 0.1rem;" src="../../static/images/home-b-a.png" alt="">
          </div>
        </div>
        <div class="right" @click="jumpToHome1">
          <img style="width:0.68rem;height:0.57rem;object-fit:cover;" src="../../static/images/home-s-b.png" alt="">
        </div>
      </div>
      <div class="header-text">COOPERATIVE PARTNER</div>
    </div>
    <div class="content">
      <h3 class="title">科技金融类</h3>
      <div class="it-box">
        <div v-for="(item1, i) in itList2" :key="i" style="margin-top: .52rem;">
          <div class="row">
            <div class="block-item" :class="currentItRowIndex == i && currentItIndex == j ? 'tip-bottom': ''" v-for="(item2, j) in item1" :key="item2.id" @click="handleItemClick(i,j,item2.remark)">
              <img class="logo-img clickActive" :src="item2.logoUrlName" alt="">
            </div>
          </div>
          <div v-show="currentItRowIndex === i" class="item-remark">
            {{currentItContent}}
          </div>
        </div>
      </div>
      <h3 class="title">地产物业类</h3>
      <div class="it-box">
        <div v-for="(item1, i) in propertyList2" :key="i" style="margin-top: .52rem;">
          <div class="row">
            <div class="block-item" :class="currentPropertyRowIndex == i && currentPropertyIndex == j ? 'tip-bottom': ''" v-for="(item2, j) in item1" :key="item2.id" @click="handleItemClick2(i,j,item2.remark)">
              <img class="logo-img clickActive" :src="item2.logoUrlName" alt="">
            </div>
          </div>
          <div v-show="currentPropertyRowIndex === i" class="item-remark">
            {{currentPropertyContent}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { partner_page } from '@/api/synopsis.js'
export default {
  name: 'Partners',
  data() {
    return {
      currentItRowIndex: 0,
      currentItIndex: 0,
      currentItContent: '',
      currentPropertyRowIndex: -1,
      currentPropertyIndex: -1,
      currentPropertyContent: '',
      itList: [],
      itList2: [],
      propertyList: [],
      propertyList2: []
    }
  },
  created() {
    this._partner_page()
  },
  methods: {
      jumpToHome() {
        this.$router.push('/')
      },
      jumpToHome1() {
        this.$router.push('/home2')
      },     
    _partner_page() {
      partner_page({ pageNum: 1, pageSize: 100 }).then(res => {
        if (res.code === 0) {
          res.data?.list.map(item => {
            if (item.type === 'partner_IT') {
              this.itList.push(item)
            } else if (item.type === 'partner_estate') {
              this.propertyList.push(item)
            }
          })
          // 根据布局，需把动态数据改为3列为单位的二维数组，方便每一行下面新插入一行
          var len = 0
          this.itList.length % 3 === 0 ? len = this.itList.length / 3 : len = Math.floor(this.itList.length / 3) + 1
          for (let i = 0; i < len; i++) {
            this.itList2.push(this.itList.splice(0, 3))
          }
          this.currentItContent = this.itList2[0][0].remark

          var len2 = 0
          this.propertyList.length % 3 === 0 ? len2 = this.propertyList.length / 3 : len2 = Math.floor(this.propertyList.length / 3) + 1
          for (let i = 0; i < len2; i++) {
            this.propertyList2.push(this.propertyList.splice(0, 3))
          }
          // 如果默认第二块不需要展示，则注释掉下面一行，并设置currentPropertyRowIndex: -1 和 currentPropertyIndex: -1
          // this.currentPropertyContent = this.propertyList2[0][0].remark
        }
      })
    },
    handleItemClick(i, j, remark) {
      this.currentPropertyRowIndex = -1
      this.currentPropertyIndex = -1
      this.currentItRowIndex = i
      this.currentItIndex = j
      this.currentItContent = remark
    },
    handleItemClick2(i, j, remark) {
      this.currentItRowIndex = -1
      this.currentItIndex = -1
      this.currentPropertyRowIndex = i
      this.currentPropertyIndex = j
      this.currentPropertyContent = remark
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  padding: 0.36rem 0.4rem 0 0.4rem;
  box-sizing: border-box;
  width: 100%;
  height: 6.05rem;
  background: url("../../static/images/hzhb.png");
  background-size: 100% 100%;
}
.header-img {
  display: flex;
  justify-content: space-between;
}
.header-text {
  position: absolute;
  margin-left: -4rem;
  top: 50%;
  left: 50%;
  font-size: 0.56rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  letter-spacing: 3px;
}
.content {
  background: #f9f9f9;
  box-sizing: border-box;
  padding: 1rem 0 0.5rem 0.4rem;
}
.title {
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #27201b;
  margin-bottom: 0.56rem;
}
.list {
  display: flex;
  flex-wrap: wrap;
}
.list-item {
  margin-bottom: 0.8rem;
  position: relative;
  width: 3rem;
  height: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid #FFAB0A;
}
.active {
  border: 1px solid #ffab0a;
}
.clickActive {
  border-right: 1px solid #E7E7E7;
}
.logo-img {
  width: 100%;
  height: 1rem;
  object-fit: cover;
}
.popup {
  position: relative;
  right: 42%;
  bottom: -0.94rem;
}
.popup-out {
  position: absolute;
  right: 50%;
  bottom: -0.32rem;
  width: 0;
  height: 0;
  border: 0.2rem solid transparent;
  border-top: 0.2rem solid #ffab0a;
}
.popup-out1 {
  position: absolute;
  right: 50%;
  bottom: -0.3rem;
  width: 0;
  height: 0;
  border: 0.2rem solid transparent;
  border-top: 0.2rem solid #f9f9f9;
}
.list-item-content {
  margin-top: 0.5rem;
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.it-box {
  .row {
    display: flex;
    flex-wrap: wrap;
    .block-item {
      width: 2.76rem;
      height: 1.6rem;
      margin-right: 0.4rem;
      display: flex;
      align-items: center;
      // border-right: 1px solid  #000;
    }
  }
}
.item-remark {
  font-size: 0.37rem;
  color: #666;
  margin: 0.5rem 0;
  padding-right: 0.4rem;
}

.tip-bottom {
  border: 1px solid #ffab0a;
  position: relative;
}

.tip-bottom:before,
.tip-bottom:after {
  content: "";
  display: block;
  border-width: .2rem;
  position: absolute;
  bottom: -.4rem;
  left: 1.33rem;
  border-style: solid dashed dashed solid;
  border-color: #ffab0a transparent transparent transparent;
  font-size: 0;
  line-height: 0;
}

.tip-bottom:after {
  bottom: -.36rem;
  border-color: #fff transparent transparent transparent;
}
.it-box .row .block-item:nth-child(3n) .clickActive {
  border: none !important;
}
.tip-bottom .clickActive{
  border: none !important;
}
</style>